@import 'highlight.js/styles/github-dark-dimmed.css';
@import 'mine.css/dist/mine.css';
@import 'mine.css/dist/layout.css';
@import 'fragmentions/fragmention.css';
@import '../components/top-nav-bar/index.css';

/* Document level */
body {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--system-round);
}

.serif {
  font-family: var(--system-serif);

  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    font-family: var(--system-serif);
  }
}

main {
  flex-grow: 1;
}

@view-transition { navigation: auto; }

/* List styles */
ul {
  list-style-type: circle;
}


.hide {
  display: none;
}

/* Print specifics */
@media print {
  html {
    background: white;
    font-size: 55%;
  }

  :root {
    --light-accent-foreground: var(--text);
  }

  body {
    border: 0;
  }

  .markdown-body {
    max-width: 100%;
  }

  .hide-print {
    display: none;
  }

  h1 small,
  h2 small,
  h3 small,
  h4 small,
  h5 small,
  h6 small {
    color: var(--accent-foreground);
  }
}

:root {
  /* main colors */
  --dark-text: #aec2e0;
  --dark-background: #14191f;

  --dark-accent-background: #242d39;
  --dark-accent-foreground: rgb(154, 154, 154);

  /* misc colors */
  --dark-link-text: #267fb5;
  --dark-code-text: #7d8b9f;
}

